import React from 'react'
import { Link } from 'gatsby'
import s from './style.module.scss'

const C = () => (
  <nav>
    <Link className={s.container} to="/">
      UNWTTNG.COM
    </Link>
  </nav>
)

C.propTypes = {}

C.defaultProps = {}

export default C
