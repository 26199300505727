import React from 'react'
import PropTypes from 'prop-types'
// import s from './style.module.scss'

import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import META_IMAGE_SRC from '../../../images/favicon-1024.png'

const C = ({ pageDescription, image, imageAlt, titlePrefixes }) => (
  <StaticQuery
    query={graphql`
      query MetaHelmetBase {
        site {
          siteMetadata {
            microBlurb
            description
            siteName
            twitterOG {
              site
              creator
            }
            canonicalRoot
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: {
          microBlurb,
          description,
          siteName,
          twitterOG,
          canonicalRoot,
        },
      },
    }) => {
      const canonicalImage = image.startsWith('http')
        ? image
        : `${canonicalRoot}${image}`
      const expandedTitle = [...titlePrefixes, microBlurb].join(' | ')
      return (
        <Helmet>
          <html lang="en" />
          <title>{expandedTitle}</title>
          <link rel="icon" href={META_IMAGE_SRC} type="image/x-icon" />
          <meta name="description" content={pageDescription || description} />
          <meta charset="utf-8" />

          <meta property="og:title" content={expandedTitle} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content={siteName} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:site" content={twitterOG.site} />
          <meta property="twitter:creator" content={twitterOG.creator} />
          <meta property="twitter:title" content={expandedTitle} />
          <meta
            property="twitter:description"
            content={pageDescription || description}
          />
          <meta property="og:image" content={canonicalImage} />
          <meta property="twitter:image" content={canonicalImage} />
          <meta property="twitter:alt" content={imageAlt} />
        </Helmet>
      )
    }}
  />
)

C.propTypes = {
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  titlePrefixes: PropTypes.arrayOf(PropTypes.string),
}

C.defaultProps = {
  titlePrefixes: [],
}

export default C
