import React from 'react'
import s from './style.module.scss'

import Emoji from 'a11y-react-emoji'

const C = () => (
  <div className={s.container}>
    <Emoji symbol="👻" label="a ghost" />
  </div>
)

C.propTypes = {}

C.defaultProps = {}

export default C
