import React from 'react'
import PropTypes from 'prop-types'
import s from './style.module.scss'

import PageLink, { propTypes as PAGE_LINK_PROPTYPES } from '../page_link'

const C = ({ links }) => (
  <div className={s.container}>
    {links.map(linkProps => (
      <div className={s.linkContainer}>
        <PageLink {...linkProps} />
      </div>
    ))}
  </div>
)

C.propTypes = { links: PropTypes.arrayOf(PAGE_LINK_PROPTYPES).isRequired }

C.defaultProps = {}

export default C
