import React from 'react'
import PropTypes from 'prop-types'
import { Mobile, Desktop } from '../responsive'

import 'normalize.css'
import s from './blank_layout.module.scss'

const BlankLayout = ({ children }) => (
  <>
    <Mobile>
      <div className={s.layout}>{children}</div>
    </Mobile>
    <Desktop>
      <div className={`${s.layout} ${s.wide}`}>{children}</div>
    </Desktop>
  </>
)

BlankLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlankLayout
