import React, { Component } from 'react'
import MediaQuery from 'react-responsive'

const MOBILE_MAX_WIDTH = 800

class SSRMediaQueryMobileDefault extends Component {
  constructor(props) {
    super(props)
    this.state = { width: MOBILE_MAX_WIDTH - 1 }
  }

  componentDidMount() {
    try {
      this.setState({ width: undefined })
    } catch (e) {}
  }

  render() {
    const { width } = this.state
    const queryProps = { ...this.props }
    if (width) {
      queryProps.values = { width }
    }
    return <MediaQuery {...queryProps} />
  }
}

export const Desktop = props => (
  <SSRMediaQueryMobileDefault {...props} minWidth={MOBILE_MAX_WIDTH + 1} />
)
export const Mobile = props => (
  <SSRMediaQueryMobileDefault {...props} maxWidth={MOBILE_MAX_WIDTH} />
)
