import React from 'react'
import PropTypes from 'prop-types'
import s from './style.module.scss'

const C = ({ skinny, children }) => (
  <p className={`${s.container} ${skinny ? s.skinny : null}`}>{children}</p>
)

C.propTypes = { skinny: PropTypes.bool }

C.defaultProps = { skinny: false }

export default C
