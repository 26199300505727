import React from 'react'
import PropTypes from 'prop-types'
import s from './style.module.scss'

const C = ({ h, heavy, naked, skinny, topPadded, right, children: c, ...p }) =>
  h === 1 ? (
    <h1
      className={`${s.container} ${heavy ? s.heavy : ''} ${
        naked ? s.naked : ''
      } ${skinny ? s.skinny : ''} ${topPadded ? s.topPadded : ''} ${
        right ? s.right : ''
      }`}
      {...p}
    >
      {c}
    </h1>
  ) : h === 2 ? (
    <h2
      className={`${s.container} ${heavy ? s.heavy : ''} ${
        naked ? s.naked : ''
      } ${skinny ? s.skinny : ''} ${topPadded ? s.topPadded : ''} ${
        right ? s.right : ''
      }`}
      {...p}
    >
      {c}
    </h2>
  ) : h === 3 ? (
    <h3
      className={`${s.container} ${heavy ? s.heavy : ''} ${
        naked ? s.naked : ''
      } ${skinny ? s.skinny : ''} ${topPadded ? s.topPadded : ''} ${
        right ? s.right : ''
      }`}
      {...p}
    >
      {c}
    </h3>
  ) : h === 4 ? (
    <h4
      className={`${s.container} ${heavy ? s.heavy : ''} ${
        naked ? s.naked : ''
      } ${skinny ? s.skinny : ''} ${topPadded ? s.topPadded : ''} ${
        right ? s.right : ''
      }`}
      {...p}
    >
      {c}
    </h4>
  ) : h === 5 ? (
    <h5
      className={`${s.container} ${heavy ? s.heavy : ''} ${
        naked ? s.naked : ''
      } ${skinny ? s.skinny : ''} ${topPadded ? s.topPadded : ''} ${
        right ? s.right : ''
      }`}
      {...p}
    >
      {c}
    </h5>
  ) : h === 6 ? (
    <h6
      className={`${s.container} ${heavy ? s.heavy : ''} ${
        naked ? s.naked : ''
      } ${skinny ? s.skinny : ''} ${topPadded ? s.topPadded : ''} ${
        right ? s.right : ''
      }`}
      {...p}
    >
      {c}
    </h6>
  ) : null

C.propTypes = {
  h: PropTypes.number,
  heavy: PropTypes.bool,
  naked: PropTypes.bool,
  skinny: PropTypes.bool,
  topPadded: PropTypes.bool,
  right: PropTypes.bool,
}

C.defaultProps = {
  h: 1,
  heavy: false,
  naked: false,
  skinny: false,
  topPadded: false,
  right: false,
}

export default C
