import React from 'react'

import BlankLayout from '../components/layouts/blank_layout'
import PageHeading from '../components/common/page_heading'
import PageLinkList from '../components/common/page_link_list'
import ThatsAll from '../components/common/thats_all'
import TopBar from '../components/common/top_bar'
import MetaHelmet from '../components/common/meta_helmet'

import {
  PALETTE_BASE_LIGHT,
  PALETTE_MUTED,
  PALETTE_MAIN_DARK,
} from '../variables'

import META_IMAGE_SRC from '../images/seal.jpg'

export default () => (
  <BlankLayout>
    <MetaHelmet
      titlePrefixes={['Blog']}
      image={META_IMAGE_SRC}
      imageAlt="A barking seal"
    />
    <TopBar background={PALETTE_MUTED} foreground={PALETTE_BASE_LIGHT} />
    <PageHeading
      text="The blog"
      background={PALETTE_MUTED}
      foreground={PALETTE_BASE_LIGHT}
    />
    <PageLinkList
      links={[
        {
          pageID: 'eb07e565-2d98-4cad-a366-469d12f61036',
          background: PALETTE_BASE_LIGHT,
          foreground: PALETTE_MAIN_DARK,
        },
        {
          pageID: '1a27b1c9-42fb-4d66-9aaa-61caa2c9337a',
          background: PALETTE_BASE_LIGHT,
          foreground: PALETTE_MAIN_DARK,
        },
        {
          pageID: '2d100a6c-680e-4ae6-90f4-a749493090bf',
          background: PALETTE_BASE_LIGHT,
          foreground: PALETTE_MAIN_DARK,
        },
      ]}
    />
    <ThatsAll />
  </BlankLayout>
)
