import React from 'react'
import PropTypes from 'prop-types'
import s from './style.module.scss'
import { PALETTE_MUTED, PALETTE_BASE_LIGHT } from '../../../variables'
import H from '../header'

const C = ({ text, background, foreground, ...hProps }) => (
  <header
    className={s.container}
    style={{ backgroundColor: background, color: foreground }}
  >
    <H naked {...hProps}>
      {text}
    </H>
  </header>
)

C.propTypes = {
  text: PropTypes.string.isRequired,
  background: PropTypes.string,
  foreground: PropTypes.string,
}

C.defaultProps = {
  background: PALETTE_MUTED,
  foreground: PALETTE_BASE_LIGHT,
}

export default C
