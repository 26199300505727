export const PALETTE_BASE_LIGHT = '#ffffff'
export const PALETTE_BASE_DARK = '#0b3c49'
export const PALETTE_MAIN_DARK = '#333333'
export const PALETTE_HIGHLIGHT = '#fcddf2'
export const PALETTE_MUTED = 'rgb(225, 137, 118)'

export const PALETTE = {
  BASE_LIGHT: PALETTE_BASE_LIGHT,
  BASE_DARK: PALETTE_BASE_DARK,
  MAIN_DARK: PALETTE_MAIN_DARK,
  HIGHLIGHT: PALETTE_HIGHLIGHT,
  MUTED: PALETTE_MUTED,
}
