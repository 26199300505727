import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import find from 'lodash.find'

import { PALETTE_BASE_LIGHT, PALETTE_MAIN_DARK } from '../../../variables'
import Header from '../header'
import P from '../paragraph'

import s from './style.module.scss'

const InternalWrapper = ({
  background,
  foreground,
  right,
  children,
  ...linkProps
}) => (
  <Link
    className={`${s.container} ${right ? s.right : ''}`}
    to={linkProps.link}
    style={{ backgroundColor: background, color: foreground }}
  >
    {children}
  </Link>
)

const ExternalWrapper = ({
  background,
  foreground,
  right,
  children,
  ...linkProps
}) => (
  <a
    className={`${s.container} ${right ? s.right : ''}`}
    href={linkProps.link}
    style={{ backgroundColor: background, color: foreground }}
  >
    {children}
  </a>
)

const C = ({ pageID, external, background, foreground, ...overrideProps }) => (
  <StaticQuery
    query={graphql`
      query PageDataForPageLinks {
        site {
          siteMetadata {
            pageData {
              id
              path
              featureTitle
              featureDescription
            }
          }
        }
      }
    `}
    render={data => {
      const page = find(data.site.siteMetadata.pageData, pg => pg.id === pageID)
      if (!page && !external) {
        return null
      }
      const linkProps = Object.assign(
        {},
        page
          ? {
              title: page.featureTitle,
              description: page.featureDescription,
              link: page.path,
            }
          : {},
        overrideProps
      )

      const Inner = () => (
        <>
          <Header
            h={2}
            heavy
            skinny
            background={background}
            foreground={foreground}
          >
            {linkProps.title}
          </Header>
          <P skinny className={s.description}>
            {linkProps.description}
          </P>
        </>
      )

      return external ? (
        <ExternalWrapper
          background={background}
          foreground={foreground}
          {...linkProps}
        >
          <Inner />{' '}
        </ExternalWrapper>
      ) : (
        <InternalWrapper
          background={background}
          foreground={foreground}
          {...linkProps}
        >
          <Inner />
        </InternalWrapper>
      )
    }}
  />
)

export const propTypes = {
  pageID: PropTypes.string,
  external: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  background: PropTypes.string,
  foreground: PropTypes.string,
  right: PropTypes.bool,
}

C.propTypes = propTypes

C.defaultProps = {
  external: false,
  background: PALETTE_BASE_LIGHT,
  foreground: PALETTE_MAIN_DARK,
  right: false,
}

export default C
